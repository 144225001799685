<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" />
        <v-row>
          <v-col
            cols="12"
            :md="showHelp ? 12 : 6"
            sm="3"
            class="pr-0"
            v-for="item in views"
            :key="item.id"
          >
            <v-card
              color="primary"
              dark
              @click="whereToPush(item)"
              height="100px"
            >
              <v-card-title class="text-h5">
                {{ item.title }}
              </v-card-title>

              <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mapActions } from "vuex";

export default {
  name: "ConfiguracionAdmSistema",
  components: { PageHeader, Ayuda },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como página visitada si ingreso por url
      this.optionCode
    );
    this.setPermisos();
    this.views.sort(function(a, b) {
      return a.order - b.order;
    });
  },
  data() {
    return {
      title: enums.titles.CONFIGURACION_ADM_SISTEMA,
      allowedActions: null,
      optionCode: enums.webSiteOptions.CONFIGURACION_ADM_SISTEMA,
      views: [],
      showExpand: false,
      showHelp: false,
      showIcon: true,
      paramGralMultimoneda: true
    };
  },
  methods: {
    ...mapActions({
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda"
    }),
    async setPermisos() {
      const response = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = response[0].multimonedaParams;
      if (response.length > 0) {
        this.allowedActions = JSON.parse(
          localStorage.getItem("allowedActions")
        );
        this.allowedActions?.forEach(x => {
          switch (x.optionCode) {
            case enums.webSiteOptions.CONFIGURACION_PERMISOS:
              this.views.push({
                id: 1,
                title: x.title,
                order: 1,
                subtitle: "",
                path: "ConfiguracionPermisos"
              });
              break;
            case enums.webSiteOptions.PANTALLA_LOGIN_ADMINISTRACION:
              this.views.push({
                id: 2,
                title: x.title,
                order: 2,
                subtitle:
                  "Permite personalizar el logo y el color de fondo de la pantalla de inicio de sesión.",
                path: "PantallaLoginAdministracion"
              });
              break;
            case enums.webSiteOptions.MONEDAS:
              if (this.paramGralMultimoneda) {
                this.views.push({
                  id: 3,
                  title: x.title,
                  order: 3,
                  subtitle:
                    "Permite configurar las monedas y sus cotizaciones.",
                  path: "MonedasGeneral"
                });
                break;
              }
            case enums.webSiteOptions.TAREAS_PROGRAMADAS:
              this.views.push({
                id: 4,
                title: x.title,
                order: 4,
                subtitle:
                  "Permite actualizar las tareas programadas de mantenimiento.",
                path: "TareasProgramadas"
              });
              break;
            case enums.webSiteOptions.DOBLE_FACTOR_AUTENTICACION:
              this.views.push({
                id: 5,
                title: x.title,
                order: 5,
                subtitle:
                  "Permite configurar el doble factor de autenticación, general y por usuario, para el inicio de sesión.",
                path: "DobleFactorAutenticacion"
              });
              break;
            case enums.webSiteOptions.GESTION_USUARIOS:
              this.views.push({
                id: 6,
                title: x.title,
                order: 6,
                subtitle: "Permite configurar los usuarios del sistema.",
                path: "GestionUsuarios"
              });
              break;
            case enums.webSiteOptions.POLITICA_CONTRASEÑAS:
              this.views.push({
                id: 7,
                title: x.title,
                order: 7,
                subtitle:
                  "Definición de la política de seguridad de las contraseñas de usuarios.",
                path: "PoliticaContraseñas"
              });
              break;
            case enums.webSiteOptions.CONFIGURACIONES_GENERALES:
              this.views.push({
                id: 8,
                title: "Configuraciones generales",
                order: 8,
                subtitle: "Configuraciones generales.",
                path: "ConfiguracionesGenerales"
              });
          }
        });
      }
    },
    whereToPush(item) {
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
      this.$router.push({
        name: item.path
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
::v-deep .text-h5 {
  font-size: 1.35rem !important;
  font-weight: 450;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
